<template lang="pug">
header#header
  nav.navbar.is-fixed-top(role="navigation" aria-label="main navigation")
    .navbar-brand
      a.navbar-item(href="/")
        .svglogoContainer.logo
          IconBaseColorfull(viewBox="0 -9 220 50" iconName="forgetman")
            IconLogo
      a.navbar-burger.burger(@click="openMobileNav" role="button" aria-label="menu" aria-expanded="false" data-target="navMenu" :class="{'is-active': mobileNavState}")
        span(aria-hidden="true")
        span(aria-hidden="true")
        span(aria-hidden="true")
    .navbar-menu(v-if="$route.name === 'forgetman'")
      .navbar-start
        router-link.navbar-item(to="/") Home
        router-link.navbar-item(to="/forgetman") forgetman 
        router-link.navbar-item(to="/help") help 
    #navManu.navbar-menu.navbar-mod(:class="{'is-active': mobileNavState}")
      .navbar-end
        .navbar-item(v-if="$route.name === 'forgetman'")
          .search
            SearchTags(:mode="mode")
          .buttons(:class="{'tagZero': !tagBucketLength}")
            TagListHint(v-if="tagBucketLength")
            ModeSelect(:uiState="getForgetsLength > 0 ? true : false")
            Add
        .navbar-item(v-else)
          router-link.navbar-item(to="/") Home
          router-link.navbar-item(to="/forgetman") forgetman 
          router-link.navbar-item(to="/help") help
</template>

<script>
import TagListHint from "@/components/ui/Tags/TagListHint.vue"
import Add from "@/components/ui/Add/Add.vue"
import ModeSelect from "@/components/ui/ModeSelect/ModeSelect.vue"
import SearchTags from "@/components/ui/Tags/SearchTags.vue"
import IconBaseColorfull from "@/components/icons/IconBaseColorfull.vue"
import IconLogo from "@/components/icons/IconLogo.vue"
export default {
  name: 'GlobalHeader',
  components: {
    Add, ModeSelect, SearchTags, TagListHint, IconBaseColorfull, IconLogo 
  },
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      isEditModalActive: false,
      mobileNavState: false,
      title: 'forgetman | A small memo tool that is fast, lightweight, and does not require registration',
      description: 'forgetman is a free, short memo tool. There is no need for troublesome membership registration or certification!'
    }
  },
  methods: {
    openEditModalActivate(){
      this.isEditModalActive = true
    },
    openMobileNav(){
      this.mobileNavState = !this.mobileNavState 
    },
    burgerForceClose(){
      this.mobileNavState = false
    },
    createTitleAndDescription(to){
      // set title
      if(to.meta.title){
        var setTitle = to.meta.title
        document.title = setTitle
      }else{
        document.title = this.title
      }
      // set description
      if(to.meta.description){
        document.querySelector("meta[name='description']").setAttribute('content', to.meta.description)
      }else{
        document.querySelector("meta[name='description']").setAttribute('content', this.description)
      }
    }
  },
  watch: {
    '$route' (to, from){
      this.createTitleAndDescription(to)
    }
  },
  computed: {
    tagBucketLength(){
      return this.$store.getters["forget/getTagBucket"].length > 0 ? true : false
    },
    getForgetsLength: {
      get(){
        return this.$store.getters['forget/getForgets'].length
      },
      set(){}
    }
  },
  mounted(){
    this.$EventBus.$on('burgerClose', this.burgerForceClose)
  }
}
</script>

<style scoped lang="stylus">
#header
  .navbar
    .navbar-brand
      .navbar-item
        padding: 3px 0px 0px 10px!important
        .svglogoContainer
          width: 195px
    #navManu.is-active
      .navbar-item
        display: flex
        justify-content: center
        .buttons
          min-width: 150px
          &.tagZero
            min-width: 100px
</style>
