<template lang="pug">
  g
    path(class="st0" d="M46.5,4.8H58v5.3h-4.7V15h4.2v5h-4.2v11h-6.8V4.8L46.5,4.8z")
    path(class="st0" d="M75.3,20.2c0,2.6-0.1,4.5-0.2,5.6s-0.5,2.1-1.2,3s-1.5,1.6-2.6,2.1s-2.4,0.7-3.9,0.7c-1.4,0-2.7-0.2-3.8-0.7c-1.1-0.4-2-1.2-2.7-2.1s-1.1-1.9-1.2-3c-0.1-1.1-0.2-3-0.2-5.7v-4.5c0-2.6,0.1-4.5,0.2-5.6c0.1-1.1,0.5-2.1,1.2-3s1.5-1.6,2.6-2.1s2.4-0.7,3.9-0.7c1.4,0,2.7,0.2,3.8,0.7c1.1,0.4,2,1.2,2.7,2.1s1.1,1.9,1.2,3s0.2,3,0.2,5.7V20.2z M68.4,11.5c0-1.2-0.1-2-0.2-2.3s-0.4-0.5-0.8-0.5s-0.6,0.1-0.8,0.4c-0.2,0.3-0.3,1.1-0.3,2.4v12.2c0,1.5,0.1,2.5,0.2,2.8c0.1,0.4,0.4,0.5,0.9,0.5s0.8-0.2,0.9-0.6s0.2-1.4,0.2-2.9L68.4,11.5L68.4,11.5z")
    path(class="st0" d="M77.8,4.8h4.8c3.2,0,5.4,0.1,6.5,0.4s2.1,0.9,2.8,1.9c0.7,1,1.1,2.6,1.1,4.9c0,2-0.3,3.4-0.8,4.1s-1.5,1.1-3,1.3c1.3,0.3,2.3,0.8,2.7,1.3c0.5,0.6,0.8,1.1,0.9,1.6S93,22,93,24.1V31h-6.3v-8.7c0-1.4-0.1-2.3-0.3-2.6c-0.2-0.3-0.8-0.5-1.7-0.5V31h-6.8L77.8,4.8L77.8,4.8z M84.6,9.3v5.8c0.8,0,1.3-0.1,1.6-0.3c0.3-0.2,0.5-0.9,0.5-2v-1.4c0-0.8-0.1-1.4-0.4-1.6C86,9.4,85.4,9.3,84.6,9.3z")
    path(class="st0" d="M111.3,14.5h-6.8v-2.4c0-1.5-0.1-2.4-0.2-2.8c-0.1-0.4-0.4-0.6-0.9-0.6c-0.4,0-0.7,0.2-0.8,0.5c-0.1,0.3-0.2,1.2-0.2,2.5v12.6c0,1.2,0.1,2,0.2,2.3c0.1,0.4,0.4,0.6,0.9,0.6s0.8-0.2,1-0.6s0.3-1.2,0.3-2.5V21h-1.4v-4h8v14H107l-0.6-1.9c-0.5,0.8-1.1,1.4-1.8,1.8c-0.7,0.4-1.5,0.6-2.5,0.6c-1.2,0-2.2-0.3-3.2-0.8c-1-0.6-1.8-1.2-2.3-2.1c-0.5-0.8-0.8-1.7-1-2.6c-0.1-0.9-0.2-2.3-0.2-4.1v-7.8c0-2.5,0.1-4.3,0.4-5.4s1-2.2,2.3-3.1s2.9-1.4,4.9-1.4s3.7,0.4,5,1.2s2.2,1.8,2.6,2.9s0.6,2.8,0.6,4.9L111.3,14.5L111.3,14.5z")
    path(class="st0" d="M113.9,4.8h11.4v5.3h-4.5V15h4.3v5h-4.3v5.7h5V31H114L113.9,4.8L113.9,4.8z")
    path(class="st0" d="M141.4,4.8v5.3h-4.1V31h-6.8V10h-4V4.8H141.4z")
    path(class="st1" d="M164,4.8V31h-6V13.3L155.7,31h-4.2L149,13.7V31h-6V4.8h8.8c0.3,1.6,0.5,3.4,0.8,5.6l1,6.7l1.6-12.2C155.1,4.8,164,4.8,164,4.8z")
    path(class="st0" d="M178.5,4.8l3.9,26.2h-7l-0.4-4.7h-2.4l-0.4,4.7h-7l3.5-26.2C168.6,4.8,178.5,4.8,178.5,4.8z M174.9,21.7c-0.4-3-0.7-6.6-1-11c-0.7,5-1.1,8.7-1.3,11H174.9z")
    path(class="st0" d="M198.8,4.8V31h-6l-3.5-11.9V31h-5.7V4.8h5.7l3.8,11.8V4.8H198.8z")
    circle(class="st2" cx="23.4" cy="17.1" r="14.7")
    circle(class="st1" cx="23.4" cy="17.1" r="11")
    path(class="st3" d="M23.4,33.4c6,0,17-1.5,17-10.3s-11.1-11.6-17-11.6s-17,2.8-17,11.6S17.4,33.4,23.4,33.4z")
    path(class="st4" d="M23.4,31.7c4.2,0,12.1-0.8,12.1-5.6s-7.9-6.4-12.1-6.4s-12.1,1.5-12.1,6.4S19.2,31.7,23.4,31.7z")
    circle(class="st5" cx="17.4" cy="25.6" r="1.8")
    circle(class="st5" cx="29.4" cy="25.6" r="1.8")
    path(class="st5" d="M23.2,27.7c0.4,0.7,0,1.6-0.9,2.2c-0.9,0.5-1.9,0.4-2.3-0.2L23.2,27.7z")
    path(class="st5" d="M23.6,27.7c-0.4,0.7,0,1.6,0.9,2.2c0.9,0.5,1.9,0.4,2.3-0.2L23.6,27.7z")
    path(class="st6" d="M25.9,14.2c0,0-1.1,0.8-2.6,0.8c-1.4,0-2.6-0.8-2.6-0.8l1.3-5.9h2.4L25.9,14.2z")
    circle(class="st3" cx="23.4" cy="6.7" r="3.4")
</template>

<script>
export default {
  name: 'IconLogo',
}
</script>

<style scoped type="text/css">
.st0{fill:#4B6886;}
.st1{fill:#D86150;}
.st2{fill:#E59383;}
.st3{fill:#384149;}
.st4{fill:#EAD7A0;}
.st5{fill:#4F4F4F;}
.st6{fill:#F4E9CB;}
</style>