<template lang="pug">
  svg(xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    xml:space="preserve"
    style="enable-background: new 0 0 300 300"
  ) 
    title(:id="iconName" lang="en") {{ iconName }}
    slot
</template>

<script>
/*
 * Usage
 * 1. import IconBase from "@/components/IconBase.vue" // Base を import
 * 2. import USEICON from "@/components/USEICON.vue" // 使いたい ICON を import
 * 3. Add components 
 * 4. template
 *     IconBase(width="30" height="30" iconName="USEICON" iconColor="#FFAA00")
 *       USEICON
 */
export default {
  name: 'IconBaseColorfull',
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    viewBox: {
      type: String,
      default: "0 0 30 30"
    }
  }
}
</script>