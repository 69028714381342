<template lang="pug">
.tagListHintContainer
  .tagListHintButton(@click="showHintModal")
    IconBase(width="38" height="38" viewBox="-3 -3 30 30" iconName="Add" iconColor="#FFFFFF")
      IconTagList
  b-modal(:active.sync="isTagListHintModal" has-modal-card scroll="keep")
    TagListHintBody 
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconTagList from "@/components/icons/IconTagList.vue"
import TagListHintBody from "@/components/ui/Tags/TagListHintBody.vue"
export default {
  name: 'TagListHint',
  components: {
    IconBase, IconTagList, TagListHintBody
  },
  data(){
    return {
      isTagListHintModal: false
    }
  },
  methods: {
    showHintModal(){
      this.isTagListHintModal = true
    }
  }
}
</script>

<style scoped lang="stylus">
</style>
