import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true,
      meta: {
        title: "forgetman | A small memo tool that is fast, lightweight, and does not require registration",
        description: "forgetman is a free, short memo tool. There is no need for troublesome membership registration or certification!"
      }
    },
    {
      path: '/forgetman',
      name: 'forgetman',
      component: () => import ('./views/Forgetman.vue'),
      meta: {
        title: "forgetman | Main App",
        description: "forgetman is a free, short memo tool. There is no need for troublesome membership registration or certification!"
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('./views/Terms.vue'),
      meta: {
        title: "forgetman | Terms",
        description: "Terms of use and disclaimer for handling forgetman. Please use this service after confirmation."
      }
    },
    {
      path: '/help',
      name: 'help',
      component: () => import('./views/Help.vue'),
      meta: {
        title: "forgetman | HELP",
        description: "A description of the button icon on forgetman. Here are some tips on how to use it."
      }
    },
    {
      path: '/thanks',
      name: 'thanks',
      component: () => import('./views/Thanks.vue')
    },
    {
      path: '/kamikaze',
      name: 'kamikaze',
      component: () => import('./views/Kamikaze.vue')
    }
  ]
})
