<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Mode Select
  .modal-card-body
    .mode_select_buttons
      .button_edit(@click="setMode('edit', $parent.close)")
        IconBase(width="80" height="80" viewBox="0 0 24 24" iconName="Edit" iconColor="#777777")
          IconEdit
      .button_delete(@click="setMode('delete', $parent.close)")
        IconBase(width="80" height="80" viewBox="0 0 24 24" iconName="Delete" iconColor="#777777")
          IconDelete
      .button_sort(@click="setMode('sort', $parent.close)")
        IconBase(width="80" height="80" viewBox="0 0 24 24" iconName="Sort" iconColor="#777777")
          IconSort
      .button_exit(@click="setMode('normal', $parent.close)")
        IconBase(width="80" height="80" viewBox="0 0 24 24" iconName="Exit" iconColor="#777777")
          IconExit
  footer.modal-card-foot
    button(class="button" type="button" @click="extendsClose($parent.close)") Close
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconEdit from "@/components/icons/IconEdit.vue"
import IconDelete from "@/components/icons/IconDelete.vue"
import IconSort from "@/components/icons/IconSort.vue"
import IconExit from "@/components/icons/IconExit.vue"
export default {
  name: 'ModeSelectModal',
  components: {
    IconBase, IconEdit, IconDelete, IconSort, IconExit
  },
  methods: {
    extendsClose(closeMethod){
      this.$EventBus.$emit('burgerClose')
      closeMethod()
    },
    setMode(mode, closeCallBack){
      this.$store.dispatch("ui/changeMode", mode)
      /*
      if(mode === 'sort')
        タグ検索絞込検索の状態を 無効化
      else
        タグ検索絞込検索の状態を 有効化 
      */
      if(mode !== 'normal'){
        this.$EventBus.$emit('callOpenSnackBar');
      }
      if (closeCallBack) closeCallBack()
      this.$EventBus.$emit('burgerClose')
    }
  },
  mounted(){
    // TODO home の SnackBarClose を叩く
    this.$EventBus.$emit('callCloseSnackBar');
  }
}
</script>

<style scoped lang="stylus">
.modal-card
  cursor: default
  .mode_select_buttons
    display: flex
    flex-direction: row
    .button_edit, .button_sort, .button_delete, .button_exit
      cursor: pointer
      margin-right: 20px
  .modal-card-foot
    display: flex
    justify-content: flex-end
</style>
