<template lang="pug">
.tagSearchContainer
  b-field
    b-taginput.searchinput(
      @input="searchTags"
      :data="tagBucket"
      icon="label"
      autocomplete
      ellipsis
      placeholder="Search Tags [or]"
      @typing="getFilteredTags"
      :disabled="state"
      tabindex="-1")
</template>

<script>
export default {
  name: 'SearchTags',
  data(){
    return {
      tagBucket: [],
      tags: []
    }
  },
  props: { 
    mode: {
      type: String,
      required: true
    }
  },
  computed:{
    state(){
      if(this.mode === 'sort')
        return true
      else
        return false
    }
  },
  methods: {
    searchTags(requestedTags){
      if( requestedTags.length === 0){
        this.$store.dispatch('forget/forgetsShowAll')
        this.$store.dispatch('forget/registerSearchTags', [])
      }else{
        this.$store.dispatch('forget/updateVisibleState', requestedTags)
        this.$store.dispatch('forget/registerSearchTags', requestedTags)
      }
    },
    getFilteredTags(searchWord){
      const TagBucket = this.$store.getters["forget/getTagBucket"]
      this.tagBucket = TagBucket.filter( tag => {
        return tag
          .toString()
          .toLowerCase()
          .indexOf(searchWord.toLowerCase()) >= 0
      }) 
    }
  }
}
</script>

<style scoped lang="stylus">
</style>
