<template lang="pug">
g
  circle(class="st0" cx="100.5" cy="100.2" r="100")
  circle(class="st1" cx="100.5" cy="100.2" r="77.9")
  path(class="st2" d="M42.9,162.7c-2.2,0.2-3.8,2.1-3.6,4.3c0.2,2.2,2.1,3.8,4.3,3.6c1.8-0.1,3.8-0.4,5.9-0.8L48,162C46.1,162.4,44.4,162.6,42.9,162.7z")
  path(class="st3" d="M152.3,132.7L125.8,148c-2.1,1.2-4.7,0.5-5.9-1.6l0,0c-1.2-2.1-0.5-4.7,1.6-5.9l26.5-15.3c2.1-1.2,4.7-0.5,5.9,1.6v0C155.1,128.9,154.4,131.5,152.3,132.7z")
  path(class="st3" d="M48,162l1.4,7.8c6.6-1.2,14.7-3.6,23-6.4l-3.9-7C60.6,159,53.6,161,48,162z")
  path(class="st4" d="M82.3,196.9c-4.9,0-8.9-4-8.9-8.9v-27.9c0-4.9,4-8.9,8.9-8.9c4.9,0,8.9,4,8.9,8.9V188C91.2,192.9,87.2,196.9,82.3,196.9z")
  path(class="st4" d="M118.7,196.9c-4.9,0-8.9-4-8.9-8.9v-27.9c0-4.9,4-8.9,8.9-8.9s8.9,4,8.9,8.9V188C127.6,192.9,123.6,196.9,118.7,196.9z")
  path(class="st5" d="M100.5,100.2c0,0,44.2,25,42.1,56.8s-36.7,30.2-42.1,30.2s-40,1.6-42.1-30.2C56.2,125.1,100.5,100.2,100.5,100.2z")
  path(class="st3" d="M100.5,131.2c25.8,0,74-6.4,74-44.8s-48.1-50.4-74-50.4s-74,12-74,50.4S74.6,131.2,100.5,131.2z")
  path(class="st4" d="M100.5,123.8c18.3,0,52.5-3.5,52.5-24.5s-34.1-27.6-52.5-27.6S48,78.2,48,99.2S82.1,123.8,100.5,123.8z")
  circle(class="st6" cx="74.5" cy="97" r="8")
  circle(class="st6" cx="126.4" cy="97" r="8")
  path(class="st6" d="M99.4,106.2c1.7,2.9,0,7-3.8,9.3c-3.8,2.3-8.3,1.8-10-1L99.4,106.2z")
  path(class="st6" d="M101.5,106.2c-1.7,2.9,0,7,3.8,9.3c3.8,2.3,8.3,1.8,10-1L101.5,106.2z")
  path(class="st7" d="M111.6,47.6c0,0-4.8,3.6-11.1,3.6s-11.1-3.6-11.1-3.6l5.8-25.7h10.5L111.6,47.6z")
  circle(class="st3" cx="100.5" cy="15.1" r="14.9")
  path(class="st4" d="M64.7,161.1c0,4.3,3.5,7.8,7.8,7.8c4.3,0,7.8-3.5,7.8-7.8v-5.6H64.7V161.1z")
  path(class="st4" d="M120.7,161.1c0,4.3,3.5,7.8,7.8,7.8c4.3,0,7.8-3.5,7.8-7.8v-5.6h-15.5V161.1z")
</template>

<script>
export default {
  name: 'IconCharacter',
}
</script>


<style scoped type="text/css">
.st0{fill:#C16863;}
.st1{fill:#B2514F;}
.st2{fill:#555D63;}
.st3{fill:#384149;}
.st4{fill:#EAD7A0;}
.st5{fill:#2A2E30;}
.st6{fill:#4F4F4F;}
.st7{fill:#F4E9CB;}
</style>