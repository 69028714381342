import moment from 'moment'
const state = {
  forgets: [],
  tagBucket: [],
  searchTags: [],
  referencedTagsAmount: []
}
const getters = {
  getForgets: (state) => state.forgets,
  getTagBucket: (state) => state.tagBucket,
  getSearchTags: (state) => state.searchTags
}
const mutations = {
  registerForget (state, forget){

    // タグ重複を解除させる
    let TempTags = forget.tags
    TempTags = TempTags.filter( (ele, idx, self) => {
      return self.indexOf(ele) === idx;
    })

    forget.tags = TempTags

    if(state.forgets.length === 0) {
      state.forgets.push(forget)
    }else{
      let hitIdx = null
      state.forgets.forEach( (f, idx) => {
        if(f.uuid === forget.uuid){
          hitIdx = idx
        }
      })
      if(hitIdx >= 0 && hitIdx !== null) {
        state.forgets[hitIdx] = forget
      }else{
        state.forgets.push(forget)
        forget.tags.forEach( t => {
          if(!state.tagBucket.includes(t)){
            state.tagBucket.push(t)
          }
        })
      }
    }
  },
  registerTagBucket (state, tags){
    // 登録・編集がされた場合、単純に tagbucket に含まれていなければ登録
    tags.forEach( t => {
      if(!state.tagBucket.includes(t)){
        state.tagBucket.push(t)
      }
    })

    // ただし、編集で削除された場合の処理がされていない。
    // 使われていないタグを削除するのはここ
    let mergeTags = []
    state.forgets.forEach( (fele, fidx, fself) => {
      mergeTags = mergeTags.concat(fele.tags)
    })
    mergeTags = mergeTags.filter( (mele, midx, mself) => {
      return mself.indexOf(mele) === midx;
    })
    state.tagBucket = mergeTags
  },
  registerSearchTags (state, tags){
    state.searchTags = tags
  },
  allocateSortNumber (state) {
    let forgetCounter = 0
    state.forgets.forEach((f, idx, fArray)=>{
      fArray[idx].sort = forgetCounter 
      forgetCounter++
    })
  },
  updateVisibleState(state, tags){
    state.forgets.forEach((f, idx) => {
      state.forgets[idx].show = false
      f.tags.forEach( tag => {
        tags.forEach( target => {
          if(tag === target){
            state.forgets[idx].show = true
          }
        })
      })
    })
  },
  forgetsShowAll(state){
    state.forgets.forEach((f, idx) => {
      state.forgets[idx].show = true
    })
  },
  updateForgets(state, newForgets){
    state.forgets = newForgets
  },
  deleteForget(state, {uuid, tags}){
    // TODO Linq.js 処理に置き換え (内部実装はさほど変わらないことになりそうだが...)
    let notDeleteTags = []
    
    // forgets全ての tags に対し、
    // 渡された tags を照合
    // tags 無いのもので参照され、使われているものはホワイトリストとして登録
    state.forgets.forEach((f, idx) => {
      f.tags.forEach((ft, ftidx, ftarr) => {
        tags.forEach((t, tidx, tarr) => {
          if (ft === t && !notDeleteTags.includes(t) && f.uuid !== uuid){
            notDeleteTags.push(t) 
          }
        })
      })
    })

    // ホワイトリストと渡された tags を照合
    // 削除すべきブラックリストを作成
    let deleteTags = [] 
    tags.forEach(tag => {
      notDeleteTags.forEach(t => {
        if(!notDeleteTags.includes(tag)){
          deleteTags.push(tag)
        }
      })
    })

    if(notDeleteTags.length === 0){
      tags.forEach(ele => {
        deleteTags.push(ele)
      })
    }

    // ブラックリストと tagbucket を照合
    // tagBucket を再生成
    let commitTags = []
    state.tagBucket.forEach( (tag, idx, arr) => {
      if (!deleteTags.includes(tag)){
        commitTags.push(tag)
      }
    })

    // forget 削除
    state.forgets.forEach((f, idx) => {
      if(f.uuid === uuid){
        state.forgets.splice(idx, 1)
      }
    })

    // 更新
    state.tagBucket = commitTags 
  },
  forgetDataStructureUpdator(state){
    let tempForgets = [].concat(state.forgets)
    let m = moment().format('YYYY.MM.DD')
    tempForgets.forEach( (ele, idx, arr) => {
      // 2019.9.2 add register date
      if(!tempForgets[idx].registerDate){
        tempForgets[idx].registerDate = m 
      }
      // 2019.9.2 add clipboard copy times
      if(!tempForgets[idx].copyTimes){
        tempForgets[idx].copyTimes = 0 
      }
      // 2019.9.2 add short note
      if(!tempForgets[idx].comment){
        tempForgets[idx].comment = '' 
      }
    })
    state.forgets = tempForgets
  },
  addClipBoardCopyTimes(state, uuid){
    let targtIdx = state.forgets.findIndex((ele, idx, arr) => {
      return ele.uuid === uuid
    })
    state.forgets[targtIdx].copyTimes++
  }
}
const actions = {
  registerForget (context, forget) { context.commit('registerForget', forget) },
  registerTagBucket (context, tags) { context.commit('registerTagBucket', tags)},
  allocateSortNumber (context) { context.commit('allocateSortNumber')},
  updateVisibleState (context, tags) { context.commit('updateVisibleState', tags)},
  forgetsShowAll (context) { context.commit('forgetsShowAll')},
  updateForgets (context, newForgets) { context.commit('updateForgets', newForgets)},
  deleteForget (context, {uuid, tags}) { context.commit('deleteForget', {uuid, tags})},
  registerSearchTags (context, searchTags) { context.commit('registerSearchTags', searchTags)},
  forgetDataStructureUpdator (context) { context.commit('forgetDataStructureUpdator')},
  addClipBoardCopyTimes (context, uuid) { context.commit('addClipBoardCopyTimes', uuid)} 
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} 