<template lang="pug">
.home.mainContainer
  main#main
    .mainWelcomeContainer
      first-access(v-if="isFirstAccess")
      existing-access(v-else)
</template>

<script>
import FirstAccess from "@/components/ui/Hero/FirstAccess.vue"
import ExistingAccess from "@/components/ui/Hero/ExistingAccess.vue"

export default {
  name: 'home',
  props: {
    mode: {
      type: String,
      required: true,
      default: 'normal',
    }
  },
  components: {
    FirstAccess, ExistingAccess
  },
  computed: {
    isFirstAccess(){
      return this.$store.getters['user/getFirstAccess']
    },
  },
  methods: {
  },
  mounted(){
  },
  created(){
  }
}
</script>

<style scoped lang="stylus">
.home
  width: 90%
  margin: 40px auto 0px auto
  padding-bottom: 120px
  .flip-list-move
    transition: transform 0.5s
</style>
