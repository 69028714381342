<template lang="pug">
  .modal-card.editModal
    header.modal-card-head
      p.modal-card-title Register your lapse of memory.
    section.modal-card-body
      .editBodyContainer
        h2.memoTypeHeader(v-if="parseInt(fg.columnNum) === 1") Single Memo
        h2.memoTypeHeader(v-if="parseInt(fg.columnNum) === 2") Conbination Memo
        .inputContainer
          
          .inputHeaderContainer.columns.is-mobile(v-if="parseInt(fg.columnNum) === 2")

            .inputHeaderInnerContainer.column
              label.label Title (required)
              .control.is-expanded.is-clearfix
                input.input(type="text" placeholder="e.g. Forgetman" v-model="fg.title" tabindex="-1")

            .inputHeaderInnerContainer.column
              label.label Url (optional)
              .control.is-expanded.is-clearfix
                input.input(type="url" placeholder="e.g. https://forgetman.app/" v-model="fg.url" tabindex="-1")

          h3.columnTitle(v-if="parseInt(fg.columnNum) === 2") Column 1
          .inputFirstContainer.columns.is-mobile

            .inputKeyValueInnerContainer.column
              label.label Key 
              .control.is-expanded.is-clearfix
                input.input(type="text" placeholder="e.g. Question #1" v-model="fg.body.column1.key" tabindex="-1")

            .inputKeyValueInnerContainer.column
              label.label Value 
              .control.is-expanded.is-clearfix
                input.input(:type="column1ValueType" placeholder="e.g. Q1 Answer" v-model="fg.body.column1.value" tabindex="-1")
                b-switch.switch(:outlined="switchOption" :rounded="switchOption" v-model="fg.body.column1.secret" size="is-small" true-value="hide" false-value="show") {{ fg.body.column1.secret }}

          h3.columnTitle(v-if="parseInt(fg.columnNum) === 2") Column 2
          .inputSecondContainer.columns.is-mobile(v-if="parseInt(fg.columnNum) === 2")

            .inputKeyValueInnerContainer.column
              label.label Key 
              .control.is-expanded.is-clearfix
                input.input(type="text" placeholder="e.g. Question #2" v-model="fg.body.column2.key" tabindex="-1")

            .inputKeyValueInnerContainer.column
              label.label Value 
              .control.is-expanded.is-clearfix
                input.input(:type="column2ValueType" placeholder="e.g. Q2 Answer" v-model="fg.body.column2.value" tabindex="-1")
                b-switch.switch(:outlined="switchOption" :rounded="switchOption" v-model="fg.body.column2.secret" size="is-small" true-value="hide" false-value="show") {{ fg.body.column2.secret }}

        .inputTagsContainer
          // INPUT TAGS
          b-field(label="Add some tags.")
            b-taginput(
              @input="registerTags"
              :value="fg.tags"
              ellipsis
              icon="label"
              autocomplete
              :data="tagBucket"
              @typing="getFilteredTags"
              :allow-new="true"
              :allow-duplicates="true"
              placeholder="Add some tags."
              tabindex="-1")
      
      .registerdTagsList(v-if="registerdTagsList.length > 0")
        b-field(label="Registerd Tags")
          b-taginput(:value="registerdTagsList" disabled)

    footer.modal-card-foot
      .columnSelectContainer
        b-field
          b-radio-button(@input="columnChange" v-model="fg.columnNum" native-value="1" :disabled="mode==='edit'") 1
          b-radio-button(@input="columnChange" v-model="fg.columnNum" native-value="2" :disabled="mode==='edit'") 2
      b-button(@click="extendClose($parent.close)" type="is-white") Close
      b-button(type="is-primary" @click="registerForget($parent.close)") Register
</template>

<script>
export default {
  name: 'FGEditModal',
  props: {
    forget: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      fg: Object.assign({}, this.forget),
      switchOption: true,
      tagBucket: [],
    }
  },
  computed: {
    column1ValueType() {
      return this.fg.body.column1.secret === 'show' ? 'text' : 'password'
    },
    column2ValueType() {
      return this.fg.body.column2.secret === 'show' ? 'text' : 'password'
    },
    registerdTagsList(){
      return this.$store.getters["forget/getTagBucket"]
    }
  },
  methods: {
    getFilteredTags(searchWord){
      const TagBucket = this.$store.getters["forget/getTagBucket"]
      this.tagBucket = TagBucket.filter( tag => {
        return tag
          .toString()
          .toLowerCase()
          .indexOf(searchWord.toLowerCase()) >= 0
      }) 
    },
    async registerForget(closeCallBack){

      await this.$store.dispatch("forget/registerForget", this.fg)
      await this.$store.dispatch("forget/allocateSortNumber")
      await this.$store.dispatch("forget/registerTagBucket", this.fg.tags)
      if(this.$store.getters["forget/getSearchTags"].length > 0){
        this.$store.dispatch('forget/updateVisibleState', this.$store.getters["forget/getSearchTags"])
      }
      await this.$EventBus.$emit('execResetForget')
      if(this.mode === 'edit' ){
        this.$EventBus.$emit('callCloseSnackBar')
        this.$store.dispatch("ui/changeMode", 'normal')
      }else{
        this.$EventBus.$emit('burgerClose')
      }
      closeCallBack()
      this.$buefy.toast.open({
        duration: 3000,
        message: `Data updated successfully!`,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },
    registerTags(tags){
      this.fg.tags = tags
    },
    extendClose(closeCallBack){
      if(this.mode === 'edit'){
        this.$EventBus.$emit('callOpenSnackBar')
      }
      closeCallBack()
      this.$EventBus.$emit('burgerClose')
    },
    columnChange(column){
      this.fg.columnNum = column
    }
  },
  created(){
    if(!this.fg.uuid){
      this.fg.uuid = this.createUuId()
    }
    this.$EventBus.$emit('callCloseSnackBar')
  }
}
</script>

<style scoped lang="stylus">
.memoTypeHeader
  font-weight: bolder
.modal-card-head
  border-top-left-radius: 0
  border-top-right-radius: 0
  padding: 15px
  .modal-card-title
    font-weight: bolder
p.modal-card-title
  font-size: 0.9rem!important
.inputKeyValueInnerContainer
  position: relative
  .switch
    position: absolute
    top: -22px
    right: 0
.inputContainer
  margin-top: 10px
  .columnTitle
    font-size: 0.9rem
    font-weight: bolder
.modal-card-foot
  display: flex
  justify-content: flex-end
.columnSelectContainer
  padding-right: 30px
.inputHeaderInnerContainer
  .label
    font-size: 0.9rem!important
    padding-left: 0px!important
.inputTagsContainer
  margin-top: 10px
</style>
