<template lang="pug">
g
  path(class="st0" d="M294.9,242.9H4.9c-2.7,0-5-2.2-5-5V4.9c0-2.7,2.2-5,5-5h290c2.8,0,5,2.2,5,5v233C299.9,240.7,297.6,242.9,294.9,242.9z")
  path(class="st1" d="M138.6,146.5c-14.3,0-41-15.6-41-45.3v-1.8h1.8c25.8,0,46.3,17.2,53.5,44.9l0.6,2.3H138.6z")
  path(class="st0" d="M99.4,101.2c23.1,0,44.2,14.4,51.8,43.5c0,0-5.4,0-12.6,0C125.3,144.7,99.4,129.7,99.4,101.2 M99.4,97.5h-3.6v3.6c0,31,27.9,47.2,42.8,47.2h12.6h4.7l-1.2-4.5c-3.8-14.6-11.2-26.6-21.3-34.6C123.8,101.6,112.1,97.5,99.4,97.5L99.4,97.5z")
  path(class="st2" d="M141.1,172.6h5.2c0,0-0.5-5.6,1.3-17.9c1.8-12.3,10.3-36.8,24.7-42.3c-8.9,11.1-14.9,19.7-17.5,33c15.2,0,48.7-17.3,48.7-48.9C170.5,96.5,141.1,121.5,141.1,172.6z")
  path(class="st0" d="M203.5,96.5L203.5,96.5 M203.5,96.5c0,31.6-33.5,48.9-48.7,48.9c2.6-13.3,8.7-22,17.5-33c-14.4,5.5-22.9,30-24.7,42.3c-1.8,12.3-1.3,17.9-1.3,17.9h-5.2C141.1,121.5,170.5,96.5,203.5,96.5 M206.5,93.5h-3c-17.9,0-34.2,7.1-45.8,19.9c-12.8,14.1-19.6,34.6-19.6,59.2v3h3h5.2h3.2l-0.3-3.2c0,0-0.4-5.5,1.3-17.2c0.3-2.1,0.8-4.3,1.4-6.7h2.9c7.7,0,20.6-4.1,31.9-13.3c12.7-10.3,19.8-24,19.8-38.6V93.5L206.5,93.5z M203.5,93.5L203.5,93.5L203.5,93.5L203.5,93.5zM203.5,99.5L203.5,99.5L203.5,99.5L203.5,99.5z")
  path(class="st3" d="M149.9,169.2c-36.4,0-66-29.5-66-66c0-17.1,6.5-32.7,17.2-44.4c-15.6,13.6-25.5,33.6-25.5,56c0,41,33.2,74.2,74.2,74.2s74.2-33.2,74.2-74.2c0-22.3-9.9-42.4-25.5-56c10.7,11.7,17.2,27.3,17.2,44.4C215.8,139.7,186.3,169.2,149.9,169.2z")
  path(class="st4" d="M149.9,102.4c14.6,0,20.4-21,0-48.6C129.5,81.5,135.3,102.4,149.9,102.4z")
</template>

<script>
export default {
  name: 'IconEasy'
}
</script>


<style scoped type="text/css">
.st0{fill:#FEFFF3;}
.st1{fill:#2B6D20;}
.st2{fill:#46963B;}
.st3{fill:#6D4915;}
.st4{fill:#509DBC;}
</style>