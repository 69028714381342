import Vue from 'vue'
import Vuex from 'vuex'

import forget from './modules/ForgetManager'
import user from './modules/UserInfo'
import ui from './modules/UiState'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    forget,
    user,
    ui
  },
  plugins: [createPersistedState({
    key: 'forgetman',
    //paths: 'storage.forgetman',
    }
  )],
})