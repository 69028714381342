import moment from 'moment'

const state = {
  uuid: null,     // ユーザー固有 uuid
  firstAccess: true, // 初アクセス判別
  lastVisist: null,   // 最終訪問日
  forgetCount: 0  // 登録 forget 数
}
const getters = {
  getUuId: state => state.uuid,
  getFirstAccess: state => state.firstAccess,
  getLastVisit: state => state.lastVisist,
  getForgetCount: state => state.forgetCount
}
const mutations = {
  /*
   * 初回訪問判別
   * @param uuid: string
   * # 初回訪問時に uuid を登録
   * # 初回訪問フラグを立てる (uuid があればこのフラグ要らない？要検討)
   */
  checkExists (state, uuid){
    if(state.uuid == null){
      state.firstAccess = true
      state.uuid = uuid 
    }else{
      state.firstAccess = false 
    }
  },

  /*
   * 最終訪問日時の更新
   * @param formattedDate: string
   * # 最終訪問日時を更新
   */
  updateVisit (state){
    let formatDate = moment(new Date).format('YYYYMMDDHHmmss')
    state.lastVisist = formatDate
  }
}
const actions = {
  checkExists (context, uuid){ context.commit('checkExists', uuid) },
  updateVisit (context, formattedDate){ context.commit('updateVisit', formattedDate) },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} 
