import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import Buefy from 'buefy'
import VueClipboard from 'vue-clipboard2'
import VeeValidate from 'vee-validate'
import EventBus from './EventBus'
import moment from 'moment'
const UAParser = require('ua-parser-js')
const uuid = require('uuid/v4')
const mixin = {
  methods: {
    createUuId: uuid,
    ua: UAParser,
    m: moment
  }
}
Vue.mixin(mixin)
Vue.use(EventBus)
Vue.use(VueClipboard)
Vue.use(VeeValidate)
Vue.use(Buefy, {
  defaultIconPack: 'mdi',
  defaultContainerElement: '#app',
})

store.dispatch('forget/forgetDataStructureUpdator')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
