<template lang="pug">
g
  path(class="st0" d="M295,243H5c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h290c2.8,0,5,2.2,5,5v233C300,240.8,297.8,243,295,243z")
  polygon(class="st1" points="259.7,52.1 243.9,36.2 180.4,99.7 180.3,99.8 196.1,115.6 196.2,115.6 ")
  path(class="st2" d="M274.9,29l-8-8c-2.2-2.2-5.7-2.2-7.9,0l-7.2,7.2l15.9,15.9l7.2-7.2C277.1,34.7,277.1,31.2,274.9,29z")
  rect(x="250.2" y="29" transform="matrix(0.7071 -0.7071 0.7071 0.7071 46.5098 192.6319)" class="st3" width="11.2" height="22.4")
  polygon(class="st2" points="196.1,115.6 180.3,99.8 175.5,114.7 181.2,120.4 ")
  polygon(class="st4" points="181.2,120.4 175.5,114.7 172.8,123.2 ")
  path(class="st1" d="M172.1,121.1c-11.5-6.1-23.9-11-36.7-13.6c-12.3-2.4-27.3-3-37.7,5.4c-9.7,7.8-10.4,21.2-3.5,31.1c8.3,11.8,24.6,15.6,37.7,19.1c10.4,2.8,21.7,5.2,31.1,10.7c4.3,2.5,9.1,6.3,10.6,11.3c2,6.6-2,14.5-6.5,19.1c-9.7,10-25.2,13.7-38.5,15.1c-15.3,1.6-30.9-0.2-45.6-4.4c-29.6-8.5-58.7-30.2-58.5-63.8c0-0.8-0.2-1.8-0.5-2.5c-0.1-0.2-0.4-1.3-0.4-0.6c0,12,3,24.1,9,34.5c6.6,11.3,16.7,20.3,28.1,26.6c13.3,7.4,28.6,11.8,43.7,13.5c15.7,1.8,32.1,0.8,47-4.7c11.9-4.4,24-13.3,23.6-27.4c-0.3-9.9-6-17.1-14.6-21.5c-10.6-5.5-22.7-7.9-34.1-11.1c-7.4-2.1-14.9-4.4-21.7-8.2c-7.2-4-15.5-11.3-14-20.5c2.9-17.4,23.8-20.9,38.3-19.4c15.4,1.6,30.5,7.5,44.1,14.7C172.5,124.5,173,121.6,172.1,121.1L172.1,121.1z")
</template>

<script>
export default {
  name: 'IconSimple',
}
</script>


<style scoped type="text/css">
.st0{fill:#FEFFF3;}
.st1{fill:#3D4F89;}
.st2{fill:#F7E6A1;}
.st3{fill:#1B3670;}
.st4{fill:#383838;}
</style>