import moment from 'moment'

const state = {
  mode: 'normal', // 操作中のモード
  snackBarInstance: null
}
const getters = {
  getMode: state => state.mode
}
const mutations = {
  /*
   * 操作モード変更/状態保存
   * @param mode: string
   * # 操作中のモードを管理
   */
  changeMode (state, mode){
    state.mode = mode
  }
}
const actions = {
  changeMode (context, mode){ context.commit('changeMode', mode) }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} 
