<template lang="pug">
.welcomeContainer
  .welcomeGreetingContainer
    .welcomeGreetingBodyContainer
      h2.welcomeTitle Welcome to "forgetman"
      p.welcomeGreeting "forgetman" is a free, short memo tool. There is no need for troublesome membership registration or certification. It’s okay even if you don’t remember what you don’t want to remember. Leave it to Forgetman!
      p.startBtn
        router-link.button.is-primary.is-rounded(to="/forgetman") GET STARTED
        
    .mainImageContainer
      IconBaseColorfull(viewBox="0 0 230 230" iconName="forgetman")
        IconCharacter

  h2.contentTitle Example
  .homeDescriptionContainer.columns
    .descriptionColumn.column
      h3 Register
      .descriptionImageContainer
        img(src="../../../assets/add_sample_01.png")
      p.descriptionBody On the registration screen, just set the title “key” and the data “Value” that is often forgotten!
    .descriptionColumn.column
      h3 Result (List View) 
      .descriptionImageContainer
        img(src="../../../assets/add_sample_02.png")
      p.descriptionBody In the list screen, you can change the display order freely. And search is possible by setting "tag" in the data!

  h2.contentTitle Concepts
  .homeDescriptionContainer.columns
    .descriptionColumn.column
      h3 Simple
      .descriptionImageContainer
        IconBaseColorfull(viewBox="0 0 300 250" iconName="simple")
          IconSimple
      p.descriptionBody forgetman is a simple little memo tool. Since it is a simple structure, how to use it, such as "answer of secret question", "memo of shortcut key", "small diary", will spread indefinitely depending on your lovely idea!
    .descriptionColumn.column
      h3 Fast
      .descriptionImageContainer
        IconBaseColorfull(viewBox="0 0 300 250" iconName="simple")
          IconFast
      p.descriptionBody There is no communication with the server to save the data. It is fast because everything is made of JavaScript.
    .descriptionColumn.column
      h3 Easy
      .descriptionImageContainer
        IconBaseColorfull(viewBox="0 0 300 250" iconName="simple")
          IconEasy
      p.descriptionBody The operation on forgetman is only character input and icon tap. Since there are only minimal functions, anyone can easily operate.

  h2.contentTitle One day...
  p.contentBody One day... We forgot a simple command in Linux. we solved it by searching on Google, but this command was often forget and frustrating. And I realized that there are many scenes of this “often forgotten”. For example, “Answer to a secret question on some site”, “Company postal code”, “Name of the person in the next seat”. And this Forgetman was born when it was assumed that the world is full of things that people don't need to remember. That's right ! leave everything that is hard to remember to "forgetman" !
  
  h2.contentTitle Differences from other tools
  .homeDescriptionContainer.columns
    .descriptionColumn.column
      h3 Text file, MarkDown
      p.descriptionBody Memo to a file forget where the file is located.
    .descriptionColumn.column
      h3 Password(memo) Management Tool
      p.descriptionBody Member registration is a premise and usage is complicated. (※Please save the password at your own risk)
    .descriptionColumn.column
      h3 Screenshots, handwritten memo
      p.descriptionBody Image management is cumbersome. Notes may be lost. 
</template>

<script>
import IconBaseColorfull from "@/components/icons/IconBaseColorfull.vue"
import IconCharacter from "@/components/icons/IconCharacter.vue"
import IconSimple from "@/components/icons/IconSimple.vue"
import IconFast from "@/components/icons/IconFast.vue"
import IconEasy from "@/components/icons/IconEasy.vue"
export default {
  name: 'FirstAccess',
  components: {
    IconBaseColorfull, IconCharacter, IconSimple, IconFast, IconEasy
  }
}
</script>

<style scoped lang="stylus">
.welcomeContainer
  .contentTitle
    font-size: 1.5rem
    font-weight: bolder
    text-align: center
    line-height: 2em
    margin-top: 40px
    color: #444
  .welcomeGreetingContainer
    display: flex
    flex-direction: row
    .mainImageContainer
      width: 30%!important
      min-width: 100px
      padding-right: 20px
      svg 
        width:100%
        min-width: 100px!important
    .welcomeGreetingBodyContainer
      h2
        font-size: 2rem
        font-weight: bolder
        letter-spacing: 0.1em
      .welcomeGreeting
        margin-top: 20px
        line-height: 2em
        letter-spacing: 0.04em
      .attention
        font-size: 0.8rem
        line-height: 1.3em
        margin-top: 10px
        color: #999
  .homeDescriptionContainer
    margin-top: 20px!important
    h3
      font-size: 1.1rem
      font-weight: bolder
      line-height: 2.3em
</style>
