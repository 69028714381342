<template lang="pug">
footer#footer.hide
  .footerContentContainer
    ul.footer_nav
      li.footer_navItem
        router-link.footer_navLink(to="/") Home
      li.footer_navItem
        router-link.footer_navLink(to="/forgetman") forgetman 
      li.footer_navItem
        router-link.footer_navLink(to="/help") help
      li.footer_navItem
        router-link.footer_navLink(to="/terms") terms
      li.footer_navItem.char
        router-link.footer_navLink(to="/kamikaze")
          img(src="../../assets/kamikaze.svg" width="25" height="25")
    .copy forgetman&copy;2019
</template>

<script>
import { throttle } from 'lodash';
export default {
  name: 'GlobalFooter',
  data(){
    return {
      scrollY: 0,
      timeOut: null,
      isFixed: false,
    }
  },
  methods: {
    scrollController(){
      this.scrollY = window.scrollY
      let _self = this
      _self.$el.classList.add('hide')
      clearTimeout(_self.timeOut)
      _self.timeOut = setTimeout(function(){
        _self.$el.classList.remove('hide')
      }, 600)
    }
  },
  mounted(){
    document.addEventListener('scroll', throttle(this.scrollController, 16), {passive: true});
  }
}
</script>

<style scoped lang="stylus">
#footer
  width: 100%
  position: fixed
  background-image: url("../../assets/wave.svg")
  background-size: 100% 150px
  height: 150px
  bottom: 0
  transition: bottom .8s
  &.hide
    bottom: -180px
  .footerContentContainer
    padding-top: 92px
    width: 100%
    .footer_nav
      width: 100%
      display: flex
      flex-direction: row
      justify-content: center
      .footer_navItem
        display: block
        margin-right: 0.5rem
        margin-left: 0.5rem
        .footer_navLink
          lihe-height: 2em
          font-size: 0.8rem
    .copy
      text-align: center
      line-height: 1.7em
      font-size: 0.8rem
      margin-bottom: 7px
      color: #FFF
</style>
