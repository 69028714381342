<template lang="pug">
  #app
    GlobalHeader(:mode="mode")
    transition(mode="out-in")
      router-view(:mode="mode")
    GlobalFooter
</template>

<script>
import GlobalHeader from "@/components/globals/GlobalHeader.vue";
import GlobalFooter from "@/components/globals/GlobalFooter.vue";
export default {
  name: "App",
  components: {
    GlobalHeader,
    GlobalFooter
  },
  computed: {
    mode(){
      return this.$store.getters["ui/getMode"]
    }
  },
  created() {
    this.$store.dispatch("user/checkExists", this.createUuId())
    this.$store.dispatch("user/updateVisit")
  }
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
//$primary: #ff6c37;
$primary: #4B6886;
$primary-invert: findColorInvert($primary);
$primary-hover: rgb(183, 201, 221);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": ( $white, $black),
  "black": ( $black, $white),
  "light": ( $light, $light-invert),
  "dark": ( $dark, $dark-invert),
  "primary": ( $primary, $primary-invert),
  "info": ( $info, $info-invert),
  "success": ( $success, $success-invert),
  "warning": ( $warning, $warning-invert),
  "danger": ( $danger, $danger-invert),
  "twitter": ( $twitter, $twitter-invert)
);
// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@font-face {
  font-family: "materialdesignicons";
  src: url("/fonts/materialdesignicons-webfont.woff2") format("woff2"),
    url("/fonts/materialdesignicons-webfont.woff") format("woff");
}
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.switch input[type=checkbox] + .check:before {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.modal-close.is-large::after,
.modal-close.is-large::before  {
  background-color: #BBBBBB;
}
.modal-close.is-large:hover::after,
.modal-close.is-large:hover::before  {
  background-color: #FFFFFF;
}
.button {
  &.is-primary {
    color: #FFFFFF!important;
    font-weight: bolder;
  }
}
.columns:last-child {
  margin-bottom: 0px!important;
}
.label {
  font-size: 0.8rem!important;
  padding-left: 0.3rem;
  margin-bottom: 0.2rem!important;
}
.buttons {
  margin-bottom: 0px!important;
  .add {
    margin-left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: $primary;
    cursor: pointer;
    transition: background-color .4s;
    &:hover {
      background-color: $primary-hover;
    }
    @media screen and (max-width: 769px){
      &:hover {
        background-color: $primary;
      }
    }
    .addButton {
      width: 38px;
      height: 38px;
    }
  }
  .tagListHintContainer {
    margin-left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: #666666;
    cursor: pointer;
    transition: background-color .4s;
    &:hover {
      background-color: #444444;
    }
    .tagListHintButton {
      width: 38px;
      height: 38px;
    }
  } 
  .mode {
    margin-left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: #666666;
    cursor: pointer;
    transition: background-color .4s;
    &:hover {
      background-color: #444444;
    }
    .modeSelectButton {
      width: 38px;
      height: 38px;
    }
  }
  .button {
    margin-bottom: 0;
  }

}
.sortContainer, .deleteContainer, .editContainer {
  width: 100%;
  height: 150%;
  position: absolute;
  cursor: pointer;
  top: -10px;
  background-color: rgba(244,244,244,0.5);
  transition: background-color .4s;
  &:hover{
    background-color: rgba(220,220,220,0.5);
  }
  &.mulutiColumn{
    height: 150%;
    .sortButton, .deleteButton, .editButton{
      margin-top: 12px;
    }
  }
  .sortButton, .deleteButton, .editButton{
    width: 60px;
    margin: 15px auto 0 auto;
  }
}

.notices.is-bottom.has-custom-container{
  position: fixed;
}

.modal.is-active.is-full-screen .modal-close.is-large {
  top: 9px!important;
  right: 10px!important;
}

.switch input[type=checkbox]:checked + .check:before,
.switch input[type=checkbox] + .check:before {
  width: 1em!important;
  height: 1em!important;
}

.registerdTagsList {
  margin-top: 20px;
  a{
    display: none!important;
  }
  .tag {
    background-color: #AAA!important;
    color: #FFF!important;
    font-weight: bolder!important;
  }
}
.tagListHintContainer {
  .registerdTagsList {
    margin-top: 20px;
    a{
      display: none!important;
    }
    .tag {
      background-color: #AAA!important;
      color: #FFF!important;
      font-weight: bolder!important;
    }
  }
}
.mainContainer {
  padding-top: 60px!important;
}

.navbar-menu.navbar-mod.is-active {
  overflow: visible!important;
}

.loading-overlay.is-active.is-full-page {
  .loading-background {
    background-color: #FFF!important;
  }
}
</style>

<style lang="stylus">
.v-enter
  transform: translate(100px, 0)
  opacity: 0
.v-enter-to
  opacity: 1
.v-enter-active
  transition: all 1s 0s ease
.v-leave
  transform: translate(0, 0)
  opacity: 1
.v-leave-to
  transform: translate(-100px, 0)
  opacity: 0
.v-leave-active
  transition: all .5s 0s ease
</style>
