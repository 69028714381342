<template lang="pug">
  svg(xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
  ) 
    title(:id="iconName" lang="en") {{ iconName }}
    g(:fill="iconColor")
      slot
</template>

<script>
/*
 * Usage
 * 1. import IconBase from "@/components/IconBase.vue" // Base を import
 * 2. import USEICON from "@/components/USEICON.vue" // 使いたい ICON を import
 * 3. Add components 
 * 4. template
 *     IconBase(width="30" height="30" iconName="USEICON" iconColor="#FFAA00")
 *       USEICON
 */
export default {
  name: 'IconBase',
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    iconColor: {
      type: String,
      default: '#000000'
    },
    viewBox: {
      type: String,
      default: "0 0 30 30"
    }
  }
}
</script>