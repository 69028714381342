<template lang="pug">
    .modal-card
      header.modal-card-head
        p.modal-card-title Registerd Tag List
      section.modal-card-body
        .registerdTagsList(v-if="registerdTagsList.length > 0")
          b-taginput(:value="registerdTagsList" disabled)
      footer.modal-card-foot
        b-button.button(@click="$parent.close()" type="is-white") Close

</template>

<script>
export default {
  name: 'TagListHintBody',
  computed:{
    registerdTagsList(){
      return this.$store.getters["forget/getTagBucket"]
    }
  },
}
</script>

<style scoped lang="stylus">
.modal-card-foot
  justify-content: flex-end
</style>
