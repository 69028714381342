<template lang="pug">
.mode(:class="{'disabled': !uiState}")
  .modeSelectButton(@click="openModeSelectActive")
    IconBase(width="38" height="38" viewBox="-3 -3 30 30" iconName="ModeSelect" iconColor="#FFFFFF")
      IconModeSelect
  b-modal(:active.sync="isModeSelectModalActive" has-modal-card)
    ModeSelectModal
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconModeSelect from "@/components/icons/IconModeSelect.vue"
import ModeSelectModal from "@/components/ui/ModeSelect/ModeSelectModal.vue"
export default {
  name: 'ModeSelect',
  props: {
    uiState: {
      type: Boolean,
      require: true
    }
  },
  components: {
    IconBase,
    IconModeSelect,
    ModeSelectModal
  },
  data(){
    return {
      isModeSelectModalActive: false
    }
  },
  methods: {
    openModeSelectActive(){
      if(this.uiState)
        this.isModeSelectModalActive = true
    }
  }
}
</script>

<style scoped lang="stylus">
.mode.disabled
  opacity: 0.1
</style>
