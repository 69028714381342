<template lang="pug">
.add
  .addButton(@click="openAddModalActivate")
    IconBase(width="38" height="38" viewBox="-3 -3 30 30" iconName="Add" iconColor="#FFFFFF")
      IconAdd
  b-modal(:active.sync="isAddModalActive" has-modal-card full-screen)
    FGEditModal(:forget="forget" :mode="mode")
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconAdd from "@/components/icons/IconAdd.vue"
import FGEditModal from "@/components/ui/Common/FGEditModal.vue"
export default {
  name: 'Add',
  components: {
    IconBase,
    IconAdd,
    FGEditModal
  },
  data(){
    return {
      isAddModalActive: false,
      forget: null,
      mode: null
    }
  },
  methods: {
    openAddModalActivate(ev){
      this.$store.dispatch("ui/changeMode", 'normal')
      this.mode = 'normal'
      this.isAddModalActive = true
    },
    resetForget(){
      this.forget = {
        columnNum: '1',
        title: '',
        url: '',
        sort: null,
        tags: [],
        uuid: '',
        show: true,
        registerDate: this.m().format('YYYY.MM.DD'),
        copyTimes: 0,
        comment: '',
        body: {
          column1: {
            key: '',
            value: '',
            secret: 'show'
          },
          column2: {
            key: '',
            value: '',
            secret: 'show' 
          }
        }
      } 
    }
  },
  mounted(){
    this.resetForget()
  },
  created(){
    this.$EventBus.$on('execResetForget', this.resetForget)
  }
}
</script>

<style scoped lang="stylus">
</style>
