<template lang="pug">
g
  path(class="st0" d="M295.3,242.9H5.3c-2.8,0-5-2.2-5-5V4.9c0-2.7,2.2-5,5-5h290c2.8,0,5,2.2,5,5v233C300.3,240.7,298.1,242.9,295.3,242.9z")
  g
    path(class="st1" d="M250.3,125.6c-1.3-1.4-3.4-1.5-4.8-0.2l-13,11.9c-2-2.4-4.4-4.6-6.8-6.6c16.2-7.4,31-19.8,26.6-39.7c-6.5-29.7-45.8-30.9-65.8-26.6c-0.8,0.2-1.6,0.4-2.4,0.6c0.6-1.3,1.4-2.6,2.3-4c2.3,0.9,5.1,1.2,8,0.6c6.4-1.4,25-15,25-15s-12.5,0.7-25.9-2.7c-5.7-1.5-13,5.2-12.7,10.2c-6.2,4-13.6,10.2-14.8,17.7c-16.8,8.5-32.8,23.3-28.2,44.1c4.3,19.9,22.9,25.1,40.7,25c-1,2.1-2,4.3-2.7,6.5l-0.9-0.1c-3.4-0.5-6.5,1.8-7,5.2c-0.5,3.4,1.8,6.5,5.2,7l0.6,0.1c0,2.5,0.3,4.9,1,7.4c0.5,1.7,1.1,3.2,1.7,4.5c-0.8,0.3-1.6,0.6-2.3,0.8c-1.7,0.5-2.6,2.3-2.1,3.9c0.5,1.7,2.3,2.6,3.9,2.1c1.2-0.4,2.5-0.9,3.9-1.4c0.4,0.5,0.8,0.9,1.3,1.4l-1.9,4.1c-1.6,3.6,0,7.7,3.5,9.3c3.6,1.6,7.7,0,9.3-3.5l1.7-3.7c8.3,1.4,16.5-0.7,18.6-1.2c1.9-0.4,8.5-1.6,14.9-5.1l16.6,1.7c3.9,0.4,7.3-2.4,7.7-6.3c0.4-3.9-2.4-7.3-6.3-7.7l-6.4-0.7c1.2-3.4,1.7-7.5,0.9-12.4c-0.5-3.5-1.8-6.8-3.5-9.8l13.7-12.4C251.5,129.1,251.6,127,250.3,125.6L250.3,125.6z")
    path(class="st2" d="M230.5,127.1c-1.3-1.4-3.4-1.5-4.8-0.2l-13,11.9c-2-2.4-4.4-4.6-6.8-6.6c16.2-7.4,31-19.8,26.6-39.7c-6.5-29.7-45.8-30.9-65.8-26.6c-0.8,0.2-1.6,0.4-2.4,0.6c0.6-1.3,1.4-2.6,2.3-4c2.3,0.9,5.1,1.2,8,0.6c6.4-1.4,25-15,25-15s-12.5,0.7-25.9-2.7c-5.7-1.5-13,5.2-12.7,10.2c-6.2,4-13.6,10.2-14.8,17.7c-16.8,8.5-32.8,23.3-28.2,44.1c4.3,19.9,22.9,25.1,40.7,25c-1,2.1-2,4.3-2.7,6.5l-0.9-0.1c-3.4-0.5-6.5,1.8-7,5.2s1.8,6.5,5.2,7l0.6,0.1c0,2.5,0.3,4.9,1,7.4c0.5,1.7,1.1,3.2,1.7,4.5c-0.8,0.3-1.6,0.6-2.3,0.8c-1.7,0.5-2.6,2.3-2.1,3.9c0.5,1.7,2.3,2.6,3.9,2.1c1.2-0.4,2.5-0.9,3.9-1.4c0.4,0.5,0.8,0.9,1.3,1.4l-1.9,4.1c-1.6,3.6,0,7.7,3.5,9.3c3.6,1.6,7.7,0,9.3-3.5l1.7-3.7c8.3,1.4,16.5-0.7,18.6-1.2c1.9-0.4,8.5-1.6,14.9-5.1l16.6,1.7c3.9,0.4,7.3-2.4,7.7-6.3c0.4-3.9-2.4-7.3-6.3-7.7l-6.4-0.7c1.2-3.4,1.7-7.5,0.9-12.4c-0.5-3.5-1.8-6.8-3.5-9.8l13.7-12.4C231.6,130.7,231.7,128.5,230.5,127.1L230.5,127.1z")
    path(class="st3" d="M208.6,128.9c-1.3-1.4-3.4-1.5-4.8-0.2l-13,11.9c-2-2.4-4.4-4.6-6.8-6.6c16.2-7.4,31-19.8,26.6-39.7c-6.5-29.7-45.8-30.9-65.8-26.6c-0.8,0.2-1.6,0.4-2.4,0.6c0.6-1.3,1.4-2.6,2.3-4c2.3,0.9,5.1,1.2,8,0.6c6.4-1.4,25-15,25-15s-12.5,0.7-25.9-2.7c-5.7-1.5-13,5.2-12.7,10.2c-6.2,4-13.6,10.2-14.8,17.7c-16.8,8.5-32.8,23.3-28.2,44.1c4.3,19.9,22.9,25.1,40.7,25c-1,2.1-2,4.3-2.7,6.5l-0.9-0.1c-3.4-0.5-6.5,1.8-7,5.2c-0.5,3.4,1.8,6.5,5.2,7l0.6,0.1c0,2.5,0.3,4.9,1,7.4c0.5,1.7,1.1,3.2,1.7,4.5c-0.8,0.3-1.6,0.6-2.3,0.8c-1.7,0.5-2.6,2.3-2.1,3.9c0.5,1.7,2.3,2.6,3.9,2.1c1.2-0.4,2.5-0.9,3.9-1.4c0.4,0.5,0.8,0.9,1.3,1.4l-1.9,4.1c-1.6,3.6,0,7.7,3.5,9.3s7.7,0,9.3-3.5l1.7-3.7c8.3,1.4,16.5-0.7,18.6-1.2c1.9-0.4,8.5-1.6,14.9-5.1l16.6,1.7c3.9,0.4,7.3-2.4,7.7-6.3c0.4-3.9-2.4-7.3-6.3-7.7l-6.4-0.7c1.2-3.4,1.7-7.5,0.9-12.4c-0.5-3.5-1.8-6.8-3.5-9.8l13.7-12.4C209.8,132.5,209.9,130.3,208.6,128.9L208.6,128.9z")
    path(class="st4" d="M184.8,130.8c-1.3-1.4-3.4-1.5-4.8-0.2l-13,11.9c-2-2.4-4.4-4.6-6.8-6.6c16.2-7.4,31-19.8,26.6-39.7c-6.5-29.7-45.8-30.9-65.8-26.6c-0.8,0.2-1.6,0.4-2.4,0.6c0.6-1.3,1.4-2.6,2.3-4c2.3,0.9,5.1,1.2,8,0.6c6.4-1.4,25-15,25-15s-12.5,0.7-25.9-2.7c-5.7-1.5-13,5.2-12.7,10.2c-6.2,4-13.6,10.2-14.8,17.7c-16.8,8.5-32.8,23.3-28.2,44.1c4.3,19.9,22.9,25.1,40.7,25c-1,2.1-2,4.3-2.7,6.5l-0.9-0.1c-3.4-0.5-6.5,1.8-7,5.2c-0.5,3.4,1.8,6.5,5.2,7l0.6,0.1c0,2.5,0.3,4.9,1,7.4c0.5,1.7,1.1,3.2,1.7,4.5c-0.8,0.3-1.6,0.6-2.3,0.8c-1.7,0.5-2.6,2.3-2.1,3.9c0.5,1.7,2.3,2.6,3.9,2.1c1.2-0.4,2.5-0.9,3.9-1.4c0.4,0.5,0.8,0.9,1.3,1.4l-1.9,4.1c-1.6,3.6,0,7.7,3.5,9.3s7.7,0,9.3-3.5l1.7-3.7c8.3,1.4,16.5-0.7,18.6-1.2c1.9-0.4,8.5-1.6,14.9-5.1l16.6,1.7c3.9,0.4,7.3-2.4,7.7-6.3c0.4-3.9-2.4-7.3-6.3-7.7l-6.4-0.7c1.2-3.4,1.7-7.5,0.9-12.4c-0.5-3.5-1.8-6.8-3.5-9.8l13.7-12.4C185.9,134.3,186,132.2,184.8,130.8L184.8,130.8z")
    g
      path(class="st5" d="M85.5,154.3c-3.4-0.5-6.5,1.8-7,5.2c-0.5,3.4,1.8,6.5,5.2,7l4.4,0.6l1.8-12.2L85.5,154.3z")
      path(class="st6" d="M84.7,179.3c-1.7,0.5-2.6,2.3-2.1,3.9c0.5,1.7,2.3,2.6,3.9,2.1c1.4-0.4,2.9-1,4.5-1.6l-2.4-5.8C87.2,178.5,85.9,178.9,84.7,179.3z")
      path(class="st7" d="M161,137.3L142.7,154c-1.3,1.2-3.3,1.1-4.5-0.2l-0.3-0.4c-1.2-1.3-1.1-3.3,0.2-4.5l18.3-16.7c1.3-1.2,3.3-1.1,4.5,0.2l0.3,0.4C162.4,134.1,162.3,136.2,161,137.3z")
      path(class="st7" d="M88.6,177.9l2.4,5.8c4.9-2.1,10.7-5.2,16.7-8.9l-4.2-4.8C97.8,173.4,92.7,176.1,88.6,177.9z")
      path(class="st5" d="M93.4,198.7c-3.6-1.6-5.1-5.8-3.5-9.3l9.1-20.1c1.6-3.6,5.8-5.1,9.3-3.5c3.6,1.6,5.1,5.8,3.5,9.3l-9.1,20.1C101.2,198.7,97,200.3,93.4,198.7z")
      path(class="st5" d="M162.3,180.6c-0.4,3.9-3.9,6.7-7.7,6.3l-22-2.3c-3.9-0.4-6.7-3.9-6.3-7.7s3.9-6.7,7.7-6.3l22,2.3C159.9,173.2,162.7,176.7,162.3,180.6z")
      path(class="st8" d="M108.4,122.9c0,0,38.5,11.9,42.2,36.8s-23.3,29.6-27.5,30.5s-30.7,8-37.7-16.3C78.4,149.7,108.4,122.9,108.4,122.9z")
      path(class="st7" d="M113.3,145.2c20-4.4,56.2-17.5,49.7-47.2c-6.5-29.7-45.8-30.9-65.8-26.6s-55.2,21.8-48.8,51.5S93.2,149.5,113.3,145.2z")
      path(class="st5" d="M94.6,138.5c12.5-3.1,35.2-11.6,31.2-27.7S97.2,95.3,84.7,98.4C72.1,101.5,50,112.3,54,128.5S82,141.6,94.6,138.5z")
      ellipse(transform="matrix(0.9771 -0.2129 0.2129 0.9771 -24.6588 16.3575)" class="st9" cx="63.6" cy="122.7" rx="5.3" ry="5.9")
      circle(class="st9" cx="104.1" cy="112.6" r="6.3")
      path(class="st9" d="M78.2,125.6c1.9,1.9,1.4,5.4-1.1,7.9s-6,3-7.9,1.1L78.2,125.6z")
      path(class="st9" d="M79.8,125.2c-0.8,2.5,1.3,5.4,4.7,6.5c3.4,1,6.7-0.2,7.5-2.7L79.8,125.2z")
      path(class="st10" d="M76.6,82.4c-1.8-15.2,23.5-26,23.5-26l4.5,2.5c-8.7,8.6-12.5,16.9-11.9,21.7C87.1,84.8,79.8,84.8,76.6,82.4z")
      path(class="st7" d="M91.5,61.8c-1.1-5.1,6.6-12.5,12.6-11c13.4,3.4,25.9,2.7,25.9,2.7s-18.6,13.6-25,15S92.6,66.9,91.5,61.8z")
      path(class="st5" d="M131.6,158.7c-2.1,2.7-1.6,6.6,1.1,8.6c2.7,2.1,6.6,1.6,8.6-1.1l2.7-3.5l-9.7-7.5L131.6,158.7z")
  polygon(class="st1" points="133.3,99.8 130.3,94.7 233.2,59.7 237.7,76.6 ")
  polygon(class="st1" points="109.8,137.8 110.2,141.5 243.9,126.1 243.1,112.6 ")
  polygon(class="st1" points="123,170.5 243.9,192.2 248.7,181.4 ")
</template>

<script>
export default {
  name: 'IconFast',
}
</script>


<style scoped type="text/css">
.st0{fill:#FEFFF3;}
.st1{fill:#FFFFFF;}
.st2{fill:#DDDDDD;}
.st3{fill:#BFBFBF;}
.st4{fill:#8E8E8E;}
.st5{fill:#EAD7A0;}
.st6{fill:#555D63;}
.st7{fill:#384149;}
.st8{fill:#2A2E30;}
.st9{fill:#4F4F4F;}
.st10{fill:#F4E9CB;}
</style>